import { Controller } from '@hotwired/stimulus'
import { Helpers } from 'pistachio'

export default class extends Controller {
  connect() {
    this.handler = this.onFetchError.bind(this)
    document.addEventListener('fetch:error', this.handler)
  }

  disconnect() {
    document.removeEventListener('fetch:error', this.handler)
  }

  // fetch:error is triggered by Helpers.fetch
  onFetchError(event) {
    const response = event.detail

    // ignore error when fetch is aborted
    if (response.status === 0)
      return

    // assume 422 is handled (remote form handler)
    if (response.status === 422)
      return


    const message = `${response.status} ${response.statusText}`.trim()
    const authUrl = response.headers && response.headers.get('WWW-Authenticate')

    if (window.Sentry) {
      window.Sentry.captureMessage(`Fetch request failed (${message})`)
    }

    if (response.status === 401 && authUrl) {
      // delay visit so we get pending Sentry message
      setTimeout(() => Helpers.visit(authUrl), 500)
    } else {
      alert(`Error: ${message}. Please try again.`)
    }
  }
}
