import { Application } from '@hotwired/stimulus'
import { identifierForContextKey } from '@hotwired/stimulus-webpack-helpers'
import * as utilControllers from '../../utils/*_controller.js'
import * as viewComponentControllers from '../../app/components/**/*_component_controller.js'

const application = Application.start()
application.handleError = (error, message, detail) => {
  console.warn(error, message, detail)
  if (window.Sentry) {
    window.Sentry.captureException(error)
  }
}


// VIEW COMPONENT
const viewComponentDefinitions = viewComponentControllers.default.map((constructor, index) => {
  const filename = viewComponentControllers.filenames[index]
  const identifier = identifierForContextKey(filename.replace('../../app/components/', '').replace('_component_controller.js', '_controller.js'))
  const controllerConstructor = constructor.default

  return { identifier, controllerConstructor }
})
application.load(viewComponentDefinitions)

// Load controllers definitions from utils/
const utilDefinitions = utilControllers.default.map((constructor, index) => {
  const filename = utilControllers.filenames[index]
  const identifier = identifierForContextKey(filename.replace('../../utils/', ''))
  const controllerConstructor = constructor.default

  return { identifier, controllerConstructor }
})
application.load(utilDefinitions)

export default application
