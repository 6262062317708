import { Controller } from '@hotwired/stimulus'
import { ChartHelpers, d3 } from 'utils/chart_helpers'

export default class extends Controller {
  connect() {
    this.renderChart()
    this.observeResize()
  }

  disconnect() {
    this.resizeObserver.disconnect()
  }

  renderChart() {
    if (this.isRendering) {
      return
    }

    this.isRendering = true

    d3.select(this.element).selectAll('svg.chart').remove()

    const YAXIS_WIDTH = 40
    const XAXIS_HEIGHT = 25
    const PADDING = 10

    const { width, height } = this.element.getBoundingClientRect()

    if (width <= 0 || height <= 0) {
      this.isRendering = false
      return
    }

    const svg = d3.select(this.element)
      .append('svg')
      .attr('class', 'chart')
      .attr('width', '100%')
      .attr('height', '100%')
      .attr('viewBox', `0 0 ${width} ${height}`)

    const values = JSON.parse(this.element.dataset.monthlyDetails)
    const [min, max] = JSON.parse(this.element.dataset.bounds)

    const numMonths = Math.max(values.length, 12)

    const x = d3.scaleBand()
      .domain(d3.range(0, numMonths))
      .range([YAXIS_WIDTH, width])
      .paddingInner(0.3)

    const y = d3.scaleLinear()
      .domain([min, max])
      .range([height - XAXIS_HEIGHT, PADDING])

    ChartHelpers.addTooltipHandler(this.element)

    // Background
    ChartHelpers.drawMonthBars(svg, x, y)
    ChartHelpers.drawZeroLine(svg, x, y)

    // Data
    const line = d3.line()
      .x((d, i) => x(i) + 0.5 * x.bandwidth())
      .y((d, i) => y(d.balance_in_hours))
      .curve(d3.curveMonotoneX)

    svg.append('path')
      .attr('d', line(values))
      .attr('class', 'line')

    svg
      .selectAll('.point')
      .data(values)
      .enter()
      .append('circle')
      .attr('cx', (d, i) => x(i) + 0.5 * x.bandwidth())
      .attr('cy', (d, i) => y(d.balance_in_hours))
      .attr('class', 'point')
      .attr('r', 2)

    // Hover bars + tooltips
    ChartHelpers.drawHoverBars(svg, x, y, values)

    // X Axis
    ChartHelpers.drawMonthAxis(svg, x, y)

    // Y Axis
    const yAxis = d3.axisLeft()
      .scale(y)
      .ticks(5)
      .tickSize(0)

    svg.append('g')
      .attr('transform', `translate(${YAXIS_WIDTH}, 0)`)
      .call(yAxis)
      .attr('class', 'axis')

    this.isRendering = false
  }

  observeResize() {
    this.resizeObserver = new ResizeObserver(entries => {
      for (const entry of entries) {
        if (entry.target === this.element) {
          this.renderChart()
        }
      }
    })
    this.resizeObserver.observe(this.element)
  }
}
