import { Controller } from "@hotwired/stimulus"
import { Helpers } from "../../../../utils/helpers"

export default class extends Controller {
  static targets = [
    "modal",
    "message",
    "title",
    "ok",
    "cancel",
  ]

  connect() {
    if (window.Turbo) {
      window.Turbo.config.forms.confirm = this.confirm.bind(this)
    }
  }

  confirm(confirm, element, submitter) {
    if(!submitter) {
      submitter = document.querySelector(`[data-turbo-confirm="${confirm}"]`)
    }

    if(!submitter) {
      throw new Error("No submitter for confirm dialog, make sure you don't use data-turbo-confirm on a non-submitting element (e.g. <a>)")
    }

    if(element.disabled) {
      return false
    }

    this.confirmElement = submitter

    this.messageTarget.innerHTML = submitter.getAttribute("data-confirm-message")
    this.titleTarget.innerHTML = submitter.getAttribute("data-confirm-title")

    this.okTarget.innerHTML = submitter.getAttribute("data-confirm-ok")
    this.okTarget.className = `c-button ${submitter.getAttribute("data-confirm-ok-class")}`

    this.cancelTarget.innerHTML = submitter.getAttribute("data-confirm-cancel")
    this.cancelTarget.className = `c-button c-button--secondary ${submitter.getAttribute("data-confirm-cancel-class")}`

    Helpers.emit(this.modalTarget, "modal:open!")
  }

  ok() {
    Helpers.emit(this.modalTarget, "modal:close!")

    if (this.confirmElement) {
      this.confirmElement.removeAttribute("data-turbo-confirm")
      this.confirmElement.click()
    }
  }
}
