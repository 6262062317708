import { StreamActions } from "@hotwired/turbo"

StreamActions.console_log = function() {
  const message = this.getAttribute("message")
  console.log(message)
}

StreamActions.dispatch_event = function() {
  const name = this.getAttribute("name")
  const detail = this.getAttribute("detail") ? JSON.parse(this.getAttribute("detail")) : null

  const options = { bubbles: true, cancelable: true, detail }
  const event = new CustomEvent(name, options)

  this.targetElements.forEach(element => element.dispatchEvent(event))
}
