import { Controller } from '@hotwired/stimulus'
import { Helpers, toggle } from 'pistachio'

export default class extends Controller {
  static values = {
    moveShiftUrl: String,
    dataUrl: String,
  }

  static targets = [
    'calendar',

    'editShiftsButton',
    'selectedShifts',
    'numSelectedShifts',

    'addShiftsButton',
    'selectedDays',
    'numSelectedDays',
  ]

  connect() {
    this.selectedShiftIds = new Set()
    this.selectedUserDates = new Set()

    this.element.addEventListener('shifts:update!', (event) => {
      if (event.detail?.user_ids) {
        this.refreshData(event.detail.user_ids)
      }

      if (event.detail?.clear_selection) {
        this.clearSelectedDays()
        this.clearSelectedShifts()
      }
    })

    // re-select in case data has been refreshed
    this.element.addEventListener('shifts:data-refreshed', (event) => {
      this.update()
    })

    this.refreshData()
  }

  refreshData(userIds) {
    let url = this.dataUrlValue

    if (userIds) {
      userIds.forEach(userId => url = Helpers.appendParamToUrl(url, 'user_ids[]', userId))
    }

    return Helpers.fetch('get', url, {
      headers: { 'Accept': 'text/vnd.turbo-stream.html' },
    })
  }

  onShiftDragStart(event) {
    event.dataTransfer.effectAllowed = 'move'
    this.draggedShift = this.getShift(event.target)
  }

  onShiftDragEnd(event) {
    this.element.querySelectorAll('[data-drag-destination]').forEach(cell => delete cell.dataset.dragDestination)
    this.draggedShift = null
  }

  onShiftDragOver(event) {
    if (!this.draggedShift) return

    this.element.querySelectorAll('[data-drag-destination]').forEach(cell => delete cell.dataset.dragDestination)
    const cell = this.getShiftCell(event.target)
    cell.dataset.dragDestination = true
    event.preventDefault()
  }

  onShiftDrop(event) {
    if (!this.draggedShift) return

    const shift = this.draggedShift
    const cell = this.getShiftCell(event.target)

    // Don't do anything if the shift is dropped in the same cell
    if (cell === this.getShiftCell(this.draggedShift)) return

    const day = cell.querySelector('.shifts-day')
    const url = this.moveShiftUrlValue

    const params = {
      shift_id: shift.dataset.shiftId,
      date: day.dataset.date,
      user_id: day.dataset.userId,
    }

    return Helpers.fetch('put', url, {
      headers: { 'Accept': 'text/vnd.turbo-stream.html' },
      body: params,
    })
  }

  onShiftClick(event) {
    const shift = this.getShift(event.target)
    const shiftId = shift.dataset.shiftId
    if (this.selectedShiftIds.has(shiftId)) {
      this.selectedShiftIds.delete(shiftId)
    } else {
      this.selectedShiftIds.add(shiftId)
    }
    this.update()
  }

  onDayClick(event) {
    const day = this.getDay(event.target)
    const userDate = `${day.dataset.userId}:${day.dataset.date}`
    if (this.selectedUserDates.has(userDate)) {
      this.selectedUserDates.delete(userDate)
    } else {
      this.selectedUserDates.add(userDate)
    }
    this.update()
  }

  update() {
    // ADD SHIFTS TO SELECTED DAYS
    {
      const baseUrl = this.addShiftsButtonTarget.dataset.baseRemoteModalUrl
      const params = new URLSearchParams()

      this.element.querySelectorAll('.shifts-day.selected').forEach(shift => shift.classList.remove('selected'))

      let numSelectedDays = 0
      this.selectedUserDates.forEach((userDate) => {
        const [userId, date] = userDate.split(':')
        const el = this.element.querySelector(`.shifts-day[data-user-id="${userId}"][data-date="${date}"]`)
        if (!el) return
        el.classList.add('selected')
        params.append(`user_dates[${userId}][]`, date)
        numSelectedDays += 1
      })
      this.addShiftsButtonTarget.dataset.remoteModalUrl = `${baseUrl}?${params.toString()}`

      this.numSelectedDaysTarget.textContent = numSelectedDays
      toggle(this.selectedDaysTarget, numSelectedDays > 0)
    }

    // EDIT SELECTED SHIFTS
    {
      const baseUrl = this.editShiftsButtonTarget.dataset.baseRemoteModalUrl
      const params = new URLSearchParams()

      let url = baseUrl

      this.element.querySelectorAll('.shifts-shift.selected').forEach(shift => shift.classList.remove('selected'))

      let numSelectedShifts = 0
      this.selectedShiftIds.forEach((shiftId) => {
        const el = this.element.querySelector(`.shifts-shift[data-shift-id="${shiftId}"]`)
        if (!el) return

        el.classList.add('selected')

        if (url.includes('$shift_id')) {
          url = url.replace('$shift_id', shiftId)
        } else {
          params.append('other_shift_ids[]', shiftId)
        }
        numSelectedShifts += 1
      })
      this.editShiftsButtonTarget.dataset.remoteModalUrl = `${url}?${params.toString()}`

      this.numSelectedShiftsTarget.textContent = numSelectedShifts
      toggle(this.selectedShiftsTarget, numSelectedShifts > 0)
    }

  }

  getShiftCell(el) {
    return el.closest('.shifts-cell')
  }

  getShift(el) {
    return el.closest('.shifts-shift')
  }

  getDay(el) {
    return el.closest('.shifts-day')
  }

  onClearEditSelectionClick(event) {
    this.clearSelectedShifts()
  }

  onClearAddSelectionClick(event) {
    this.clearSelectedDays()
  }

  clearSelectedShifts() {
    this.selectedShiftIds.clear()
    this.update()
  }

  clearSelectedDays() {
    this.selectedUserDates.clear()
    this.update()
  }
}
