import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    this.element.addEventListener('popover:open', event => {
      this.propagateReferrer(event.detail.source, event.target)
    })

    this.element.addEventListener('remote-modal:open', event => {
      this.propagateReferrer(event.detail.source, event.target)
    })

    this.element.addEventListener('turbo:before-fetch-request', event => {
      let el = event.target.closest('[data-turbo-stream-referrer]')

      if (!el) {
        // Fallback for form links which are handled by turbo
        // Turbo creates a form for link_to which post
        // We lose referrer in this case, so try to look up original link
        // This workaround might be obsolete with https://github.com/hotwired/turbo/pull/856
        const formLink = document.querySelector(`a[href*="${event.detail.url.pathname}"][data-turbo-method]`)
        if (formLink) {
          el = formLink.closest('[data-turbo-stream-referrer]')
        }
      }

      if (el) {
        event.detail.fetchOptions.referrer = el.getAttribute('data-turbo-stream-referrer')
      }
    }, true)
  }

  propagateReferrer(source, target) {
    const from = source && source.closest('[data-turbo-stream-referrer]')
    const referrer = from && from.getAttribute('data-turbo-stream-referrer')
    if (referrer) {
      target.setAttribute('data-turbo-stream-referrer', referrer)
    } else {
      target.removeAttribute('data-turbo-stream-referrer')
    }
  }
}
