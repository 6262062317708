import * as Sentry from "@sentry/browser"

if (process.env.ENABLE_FRONTEND_ERROR_TRACKING == 'true') {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    environment: process.env.SENTRY_ENVIRONMENT,
    release: process.env.SENTRY_RELEASE,

    maxBreadcrumbs: 50,

    integrations: [
      Sentry.browserTracingIntegration(),
    ],

    tracesSampleRate: 0.05,
  });

  window.Sentry = Sentry
}

// Imports
import '@hotwired/turbo-rails'

import { Hakuna } from '../hakuna'
import { Polyfills, Helpers } from 'pistachio'
import Glass from 'glass'
import '../charts.css';

import { DateUtils } from '../date_utils'
import { Timezone } from '../timezone'

// Do the dance
window.lang = document.querySelector('html').lang
window.DateUtils = DateUtils

Polyfills.checkAndRun(main)

function main(err) {
  require('pistachio/app/components')

  require('../component_styles')
  require('../stimulus_controllers')
  require('../turbo_stream')


  Hakuna.ready(event => {
    if (event.detail && event.detail.timing) {
      const timing = event.detail.timing

      // timing is only available for subsequent turbo xhr page loads
      if (Object.keys(timing).length > 0) {
        Glass.trackXHR(timing)
      } else {
        Glass.trackPageLoad()
      }
    } else {
      Glass.trackPageLoad()
    }

    Timezone.setCookie()

    Helpers.pollAndForward()

    if (!Polyfills.browserSupportsAllFeatures()) {
      Glass.track('browser::polyfills_required', Polyfills.featureSupportList())
    }
  })
}

import 'pistachio/app/base'
import '../globals.css'
import '../events.css'
import '../print.css'

import * as images from '../images/**/*'
images.default.map((image) => image)
