import { Controller } from "@hotwired/stimulus"

// Handles clicks on <a> buttons with data-turbo-method and disables
// them while the request is being processed.
export default class extends Controller {
  BUTTON_SELECTOR = "a.c-button[data-turbo-method]:not([data-turbo-confirm])"
  SUBMITTING_SELECTOR = "a.c-button[data-submitting]"

  connect() {
    document.addEventListener("click", this.onClick.bind(this))
    document.addEventListener("turbo:submit-end", this.onSubmitEnd.bind(this))
  }

  onClick(event) {
    const button = event.target.closest(this.BUTTON_SELECTOR)

    if(!button) {
      return
    }

    if(button.hasAttribute("data-submitting")) {
      event.preventDefault()
      return false
    }
    else {
      // Add data-turbo="false" so that Turbo doesn't handle future clicks
      button.dataset.turbo = false
      button.dataset.submitting = true
      button.classList.add("disabled")
    }
  }

  // As turbo doesn't have a way to retrieve the submitter for a form link
  // we listen for the turbo:submit-end event and remove the submitting state
  // from all buttons with the data-submitting attribute.
  onSubmitEnd(event) {
    document.querySelectorAll(this.SUBMITTING_SELECTOR).forEach(button => {
      delete button.dataset.turbo
      delete button.dataset.submitting
      button.classList.remove("disabled")
    })
  }
}
